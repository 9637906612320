.fieldName {
  width: 150px;
}

.wrapper {
  border-bottom: 1px solid var(--black-extra-light);
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}
