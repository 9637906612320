.currentThumbnail {
  background-color: var(--BLACK) !important;
}

.thumbnail {
  background-color: var(--WHITE);
  cursor: pointer;
  height: 40px;
  padding: 2px;
}

.wrapper {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  grid-area: a;
  margin: 0 auto;
  overflow: auto;
  padding: 5px 10px;
  position: absolute;
  width: -moz-available;
  width: -webkit-fill-available;
  z-index: 1;
}
