.completed {
  border-color: var(--black-hover) !important;
  color: var(--black);
}

.completedLine {
  background-color: var(--black);
}
.current {
  background-color: var(--black);
  border-color: var(--black-hover) !important;
  color: var(--white);
  cursor: not-allowed;
}

.incoming {
  background-color: rgb(220, 220, 220);
  border-color: rgb(220, 220, 220) !important;
  color: var(--black);
  cursor: not-allowed;
}

.incomingLine {
  background-color: rgb(220, 220, 220);
}

.line {
  height: 1px;
  margin: 0 20px;
  width: 80px;
}

.step {
  align-items: center;
  border: 2px solid;
  border-radius: 100%;
  display: flex;
  font-weight: var(--font-weight-biggest);
  height: 30px;
  justify-content: center;
  text-align: center;
  width: 30px;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 200px;
}
