.totalPrice {
  margin-left: -35px;
  white-space: nowrap;
}

.wrapper {
  border: none !important;
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-2);
}

.wrapper div {
  font-weight: var(--font-weight-bigger);
}
