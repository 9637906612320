.articles {
  display: flex;
  flex-direction: column;
}

.articleTitle {
  width: 100%;
}

.attributes {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.header {
  border-bottom: 0.1px solid var(--black-click);
  padding-bottom: 5px;
}

.header > div {
  font-weight: var(--font-weight-biggest);
}

.infos {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.item > td {
  text-align: center;
}

.orderId {
  font-weight: var(--font-weight-bigger);
}

.product {
  width: 150px;
}

.quantity {
  width: 100px;
}

.row {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-evenly;
  text-align: center;
}

.status {
  font-weight: var(--font-weight-biggest);
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.title > div {
  font-size: var(--font-size-2);
}

.unitPrice {
  display: flex;
  justify-content: center;
  width: 120px;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  padding: 50px 20px;
}
