.children {
  align-items: center;
  border-top: 1px solid black;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
}

.title {
  align-self: flex-start;
  background-color: white;
  left: 20px;
  padding: 0 5px;
  position: relative;
  top: calc(var(--font-size-16) / 2);
  z-index: 1;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;
  width: 100%;
}
