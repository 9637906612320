.children {
  margin: 0 auto;
}

.title {
  text-align: left;
}

.wrapper {
  display: grid !important;
  grid-template-columns: auto auto;
  gap: 20px;
  width: 100%;
}
