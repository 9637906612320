.selected {
  color: var(--WHITE) !important;
  cursor: default;
  font-weight: var(--font-weight-bigger);
}

.wrapper {
  color: var(--WHITE);
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.wrapper > a {
  color: var(--WHITE);
}

.wrapper > a:not(.selected):hover {
  font-weight: var(--font-weight-bigger);
}
