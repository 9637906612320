.body {
  height: 100%;
  overflow: auto;
}

.body > :first-child {
  min-height: calc(100vh - var(--header-height) - var(--footer-height) - 100px);
  padding: 50px 20px;
}

.wrapper {
  display: grid;
  grid-template-areas: "a";
}

.wrapper > :last-child,
.wrapper > :first {
  grid-area: a;
}
