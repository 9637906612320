.wrapper {
  color: var(--WHITE);
  cursor: pointer;
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-bigger);
}

.wrapper:hover {
  font-weight: bolder;
}
