.text {
  padding: 0px !important;
  white-space: nowrap;
}

.wrapper {
  align-items: center;
  color: var(--white);
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  padding: 5px 0;
}

.wrapper:hover {
  color: var(--white-hover);
}

.wrapper:active {
  color: var(--white-click);
}

@media screen and (min-width: 700px) {
  .hide {
    display: none !important;
  }
}
