.title {
  font-size: var(--font-size-3);
  text-align: center;
}

.wrapper {
  align-items: center;
  display: grid;
  gap: 50px;
  grid-template-rows: auto auto auto auto;
  justify-content: center;
}
