.orderId {
  font-weight: var(--font-weight-bigger);
}

.title {
  font-size: var(--font-size-3);
  padding: 20px 0;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}
