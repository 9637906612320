.children {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
  width: 100%;
}

.children > a {
  border-bottom: 1px solid;
  border-color: var(--white);
  display: flex;
  justify-content: center;
  width: 100%;
}

.children:hover > a {
  border-color: var(--white-hover);
}

.children:active > a {
  border-color: var(--white-click);
}

.closeIcon {
  align-items: center;
  align-self: end;
  color: var(--white);
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-bigger);
  height: var(--header-height);
  padding: var(--header-padding);
}

.closeIcon:hover {
  color: var(--white-hover);
}

.closeIcon:active {
  color: var(--white-click);
}

.mask {
  display: none;
}

.wrapper {
  background-color: var(--BLACK);
  color: var(--WHITE);
  display: none;
  flex-direction: column;
  height: 100vh;
  min-width: 200px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}

@media screen and (max-width: 700px) {
  .mask {
    display: flex;
  }

  .wrapper {
    display: flex;
  }
}
