.buttons {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  height: 40px;
  padding: 0 20px;
}

.content {
  font-size: var(--font-size-16);
  max-width: 50vw;
  padding: 0 10px;
  text-align: center;
}

.title {
  border-bottom: 1px solid var(--BLACK);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-bigger);
  padding-bottom: 10px;
  text-align: center;
}

.wrapper {
  align-items: center;
  background-color: var(--WHITE);
  border-radius: 15px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: fit-content;
  justify-content: center;
  left: 0;
  margin: auto !important;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: fit-content;
  z-index: 10;
}
