.header {
  border: none !important;
}

.name {
  color: var(--BLACK);
}

.name:hover {
  opacity: 0.5;
}
