.addPhoto {
  background-color: inherit;
  border-style: dotted;
}

.wrapper {
  border-style: dashed;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
