.spinner {
  font-size: 50px;
}

.wrapper {
  align-items: center;
  background-color: var(--mask-background-color);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  min-height: inherit;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}
