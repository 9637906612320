.logo {
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
}

.wrapper {
  align-items: center;
  background-color: var(--BLACK);
  display: flex;
  flex-direction: row;
  height: var(--header-height);
  justify-content: space-between;
  padding: var(--header-padding);
}
