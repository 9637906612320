.aboutUs {
  border-bottom: 1px solid !important;
  border-color: var(--white);
  border-radius: 0 !important;
  color: var(--white);
  font-weight: var(--font-weight-initial) !important;
  padding: 0 !important;
}

.aboutUs:hover {
  border-color: var(--white-hover);
  color: var(--white-hover);
}

.aboutUs:active {
  border-color: var(--white-click);
  color: var(--white-click);
}

.wrapper {
  align-items: center;
  background-color: var(--BLACK);
  color: var(--WHITE);
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: var(--footer-height);
  justify-content: center;
  text-align: center;
}
