.wrapper {
  background-color: #ffc0c0;
  border: 1px solid red;
  border-radius: 3px;
  color: red;
  justify-self: center;
  padding: 5px 10px;
  text-align: center;
  width: fit-content;
}
