.close {
  align-items: center;
  background-color: white;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  grid-area: a;
  height: 20px;
  left: 10px;
  padding: 10px;
  position: fixed;
  text-align: center;
  top: 10px;
  width: 20px;
  z-index: 3;
}

.close:hover,
.close:active {
  background-color: var(--white) !important;
  font-size: large;
  font-weight: bolder;
}

.close:active {
  font-size: larger;
}

.fullScreen {
  background-color: var(--mask-background-color);
  display: grid;
  grid-template-areas: "a";
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  outline: none;
  position: absolute;
  top: 0;
  z-index: 2;
}

.fullScreen > img {
  grid-area: a;
  margin: auto;
  outline: none;
}

@media screen and (max-width: 400px) {
  .close {
    height: 50px;
    width: 50px;
  }
}
