.entries {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
}

.error {
  background-color: #ffc0c0;
  border: 1px solid red;
  border-radius: 3px;
  color: red;
  grid-column-end: span 2;
  padding: 5px;
  text-align: center;
}

.footer {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.title {
  font-size: var(--font-size-3);
  padding-bottom: 30px;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}
