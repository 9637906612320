.description {
  font-size: var(--font-size-2);
}

.icon {
  color: var(--black);
  font-size: 60px;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  width: 100%;
}
