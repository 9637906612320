.mainImage {
  cursor: pointer;
  grid-area: a;
  max-height: calc(100vh - var(--header-height));
  max-width: 100%;
}

.mainImage:focus-visible {
  outline: none;
}

.wrapper {
  cursor: pointer;
  display: grid;
  grid-template-areas: "a";
  max-width: calc(50vw - 60px);
  min-width: 200px;
  position: relative;
}
