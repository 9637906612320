:root {
  /* Colors */
  --BLACK: rgb(0, 0, 0, 1);
  --RED: rgba(255, 0, 0, 1);
  --WHITE: rgb(255, 255, 255);

  --mask-background-color: rgba(0, 0, 0, 0.45);

  /* Buttons */
  /* Black */
  --black: var(--BLACK);
  --black-click: rgba(0, 0, 0, 0.5);
  --black-disabled: var(--black-click);
  --black-hover: rgba(0, 0, 0, 0.7);
  --black-extra-light: rgba(0, 0, 0, 0.05);

  /* Danger */
  --danger: var(--RED);
  --danger-click: rgba(255, 0, 0, 0.6);
  --danger-disabled: var(--danger-click);
  --danger-hover: rgba(255, 0, 0, 0.8);

  /* text */
  --text: var(--BLACK);
  --text-click: rgba(0, 0, 0, 0.3);
  --text-disabled: var(--text-click);
  --text-hover: rgba(0, 0, 0, 0.5);

  /* White */
  --white: var(--WHITE);
  --white-click: rgb(210, 210, 210);
  --white-disabled: var(--white-click);
  --white-hover: rgb(232, 232, 232);

  /* Font sizes */
  --font-size-13: 13px;
  --font-size-16: 16px;
  --font-size-2: 20px;
  --font-size-3: 30px;
  --font-size-4: 40px;
  --font-size-5: 50px;

  /* Font weights */
  --font-weight-thin: 100;
  --font-weight-initial: 200;
  --font-weight-bigger: 300;
  --font-weight-biggest: 500;

  /* Elements' height */
  --header-height: 60px;
  --footer-height: 200px;
  --header-padding: 0 20px;
}

@media screen and (max-width: 600px) {
  :root {
    --font-size-13: 10px;
    --font-size-16: 13px;
    --font-size-2: 16px;
    --font-size-3: 20px;
    --font-size-4: 25px;
    --font-size-5: 30px;
  }
}
