.laptopWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: 100%;
}

.phoneWrapper {
  color: var(--white);
  cursor: pointer;
  display: none;
  flex-direction: column;
}

.phoneWrapper:hover {
  color: var(--white-hover);
}

.phoneWrapper:active {
  color: var(--white-click);
}

@media screen and (max-width: 700px) {
  .laptopWrapper {
    display: none;
  }

  .phoneWrapper {
    display: flex;
  }
}
