.size:disabled {
  background-color: unset;
  border-bottom: 1px solid var(--black);
  border-radius: 0;
  color: var(--black);
  font-weight: var(--font-weight-bigger);
}

.size:disabled:hover {
  cursor: default !important;
}

.size {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1px 2px;
  text-align: center;
  min-width: 20px;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: 20px;
  margin: auto;
}
