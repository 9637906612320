.black {
  background-color: var(--black);
  color: var(--white);
}

.black:disabled {
  background-color: var(--black-disabled);
}

.black:not(:disabled):hover {
  background-color: var(--black-hover);
}

.black:not(:disabled):active {
  background-color: var(--black-click);
}

.common,
.common > div {
  align-items: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-weight: var(--font-weight-bigger);
  gap: 10px;
  justify-content: center;
  padding: 5px 15px;
}

.common:disabled {
  cursor: not-allowed !important;
}

.danger {
  background-color: var(--danger);
  color: var(--white);
  font-weight: var(--font-weight-bigger);
}

.danger:disabled {
  background-color: var(--danger-disabled);
  color: var(--danger-disabled);
}

.danger:not(:disabled):hover {
  background-color: var(--danger-hover);
}

.danger:not(:disabled):active {
  background-color: var(--danger-click);
}

.danger > div {
  font-weight: var(--font-weight-bigger);
}

.text {
  background-color: var(--white);
  color: var(--black);
  padding: 5px;
}

.text:disabled {
  background-color: var(--white-disabled);
}

.text:not(:disabled):hover {
  background-color: var(--white-hover);
}

.text:not(:disabled):active {
  background-color: var(--white-click);
}

.white {
  border: 1px solid var(--black);
  background-color: var(--white);
  color: var(--black);
}

.white:disabled {
  border-color: var(--white-disabled);
  background-color: var(--white-disabled);
}

.white:not(:disabled):hover {
  background-color: var(--white-hover);
}

.white:not(:disabled):active {
  background-color: var(--white-click);
}
