.guy {
  display: flex;
  flex-direction: column;
}

.introduction {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 20px;
}

.name {
  font-style: italic;
  font-weight: var(--font-weight-bigger);
}

.photo {
  border-radius: 100%;
  height: 200px;
  margin: auto;
}

.title {
  font-size: var(--font-size-3);
  padding-bottom: 30px;
}

.us {
  text-align: center;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  margin: auto;
  max-width: 600px;
  padding: 30px 30px;
}
