.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-evenly;
  max-height: 300px;
  min-width: 300px;
}

.title {
  font-size: var(--font-size-3);
  text-align: center;
}

.wrapper {
  align-items: center;
  display: grid;
  gap: 20px;
  grid-template-rows: auto 1fr;
  justify-content: center;
}
