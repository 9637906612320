.img {
  grid-area: a;
}

.photo {
  display: grid;
  grid-template-areas: a;
}

.photo:hover > :nth-child(2) {
  display: flex;
}

.trash {
  align-items: center;
  background-color: var(--mask-background-color);
  color: var(--WHITE);
  cursor: pointer;
  display: none;
  grid-area: a;
  justify-content: center;
}
