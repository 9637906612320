.icon {
  padding: 0;
}

.icon:disabled {
  background-color: unset;
  color: var(--black-disabled);
}

.quantity {
  border: none;
  padding: 0;
  text-align: center;
  width: 100%;
}

.quantity:focus-visible {
  outline: 1px solid black;
}

.quantity::-webkit-outer-spin-button,
.quantity::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.quantity[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 10px;
}

@media screen and (max-width: 400px) {
  .wrapper {
    padding: 0;
  }
}
