.somethingWentWrong {
  font-weight: var(--font-weight-bigger);
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  text-align: center;
}
