.editableQuantities {
  width: 60px;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.name {
  font-size: var(--font-size-3);
  padding-bottom: 50px;
  text-align: center;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-evenly;
}
