.addPhoto {
  align-items: center;
  display: flex;
  font-size: var(--font-size-3);
  justify-content: center;
  line-height: var(--font-size-3);
}

.input {
  display: none;
}
