.image {
  cursor: pointer;
  max-width: 80vw;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px 0px;
  justify-content: center;
  text-align: center;
}
