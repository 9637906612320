.a {
  grid-area: a;
}

.container {
  display: grid;
  grid-template-areas: "a";
}

.details {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-evenly;
}

.entry {
  display: flex;
  flex-direction: row;
}

.form {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
}

.title {
  font-size: var(--font-size-3);
  padding: 30px 0;
  text-align: center;
  text-transform: uppercase;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
