.label {
  align-items: center;
  display: flex;
}

.size:hover {
  cursor: pointer;
  text-decoration: line-through;
}

.sizes {
  display: flex;
  flex-direction: row;
  gap: 3px;
  justify-content: center;
  width: 100%;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
