.fields {
  align-items: center;
  border-top: 1px solid var(--BLACK);
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 3px 0;
  text-align: center;
}

.linePrice {
  display: flex;
  justify-content: center;
  width: 70px;
}

.name {
  width: 150px;
}

.quantity {
  display: flex;
  justify-content: center;
  width: 100px;
}

.size {
  width: 50px;
}

.unitPrice {
  display: flex;
  justify-content: center;
  width: 120px;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

@media screen and (max-width: 400px) {
  .linePrice {
    max-width: 60px;
  }

  .name {
    max-width: 100px;
  }

  .quantity {
    max-width: 60px;
  }

  .unitPrice {
    display: none;
  }
}
