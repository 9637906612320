.plus {
  border: 1px solid var(--BLACK);
  border-radius: 50%;
  height: var(--font-size-2);
  width: var(--font-size-2);
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.wrapper > input {
  max-width: 30px;
}
