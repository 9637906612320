.image {
  align-items: center;
  display: flex;
  font-size: var(--font-size-2);
  justify-content: center;
  max-height: 65px;
  max-width: 90%;
}

.item {
  height: 100px;
  text-align: center;
  width: 100px;
}

.item > a {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  padding: 10px;
  text-align: center;
  width: inherit;
}

.label {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
}
